@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Chakra';
  src: url("../src/fonts/ChakraPetch-Regular.ttf") format('truetype');    
}

@font-face {
  font-family: 'ChakraSemi';
  src: url("../src/fonts/ChakraPetch-SemiBold.ttf") format('truetype');    
}

@font-face {
  font-family: 'TekoRegularBold';
  src: url("../src/fonts/Teko-Regular-bold.ttf") format('truetype');    
}

@font-face {
  font-family: 'Poppi';
  src: url("../src/fonts/Poppi.otf") format('truetype');    
}


html { font-family: 'Chakra'; letter-spacing: 1px;}


body {
  margin: 0;  
  background: #05113B;
}

.semi {
  font-family: 'ChakraSemi';
}

.footer-hover:hover {
  filter: invert(0.16) sepia(0.5) saturate(17) hue-rotate(307.2deg) brightness(1)
}


.bg-linear-gradient-border {
  position: relative;
  background-color: #05113b;
  border: 1px solid transparent;
  border-radius: 14px;
  transition: 0.3s all;
}

.bg-linear-gradient-border::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(to top, #FFFFFF 3%
          /*bottom-right color*/
          , #314894 56%          
      );
}

.bg-linear-gradient-border-full {
  position: relative;
  background-color: #05113b;
  border: 1px solid transparent;
  border-radius: 14px;
  transition: 0.3s all;
}

.bg-linear-gradient-border-full::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(to top, #FFFFFF 50%
          /*bottom-right color*/
          , #314894 56%          
      );
}

.ReactModal__Overlay {
  background-color: rgba(54, 54, 54, 0.233);
}

.terms-wrapper .title {
  font-size: 48px;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
  cursor: default;
}

.terms-wrapper .paragraph .p-title {
  font-size: 26px;
}

.terms-wrapper {
  height: unset;
}