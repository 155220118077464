.dot {
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
  }

.popi-font {
    font-family: 'Poppi';
    letter-spacing: 6px;
}

.lighter {
    font-family: 'TekoRegularLight';
}

.bolder {
    font-family: 'TekoRegularBold';
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

.control-dots {
    display: hidden !important;
}

.thumb.selected {
    border: none !important;
}

.thumb {
    border: none !important;
}

.carousel .thumbs-wrapper {
    margin: 20px 0 0 0 !important;
}

.carousel.big {
    height: 520px;
  }
  .carousel .card-container {
    height: 500px;
    position: relative;
    overflow: visible;
  }
  .carousel .card-container .card {
    height: 100%;
    width: 33.3%;
    transition: all 1s;
    position: absolute;
    opacity: 0.5;
    transform: scale(0.8);
    z-index: 998;
    cursor: pointer;
    user-select: none;
    overflow: visible;
  }
  .carousel .card-container .card img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
  .carousel .rects {
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel .rects .rect {
    height: 2px;
    width: 20px;
    background-color: #ddd;
    margin-right: 8px;
    cursor: pointer;
  }
  .carousel .rects .active {
    background-color: #c62f2f;
  }